import React from 'react';
import { ListItem, ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
import MaterialIcon from '../../MaterialIcon';

const LayerMenuActions = ({ menuActions, actionSectionStatus, toggleActionSectionStatus }) => {
  if (React.isValidElement(menuActions)) {
    return menuActions;
  } else {
    if (menuActions && menuActions.length > 0) {
      {
        return menuActions.map((i) => {
          if (i.childs && i.childs.length > 0) {
            return (
              <div key={i.key}>
                <ListSubheader sx={{ p: 0, color: '#000', fontWeight: 'bold' }} component="div">
                  <ListItemButton onClick={toggleActionSectionStatus.bind(this, i.key)}>
                    <ListItemIcon>
                      <MaterialIcon
                        icon={actionSectionStatus[i.key].open ? 'ExpandLess' : 'ExpandMore'}
                      />
                    </ListItemIcon>
                    <ListItemText primary={i.text} />
                  </ListItemButton>
                  {i.avatar}
                </ListSubheader>
                {actionSectionStatus[i.key].open &&
                  i.childs.map((child) => {
                    return (
                      <ListItem
                        button
                        key={child.key}
                        onClick={child.handleClick}
                        selected={child.selected}
                      >
                        <ListItemIcon>
                          <MaterialIcon icon={child.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={child.text}
                          primaryTypographyProps={{ fontWeight: child.selected ? 'bold' : '400' }}
                          secondary={child.subtitle}
                        />
                        {child.avatar}
                      </ListItem>
                    );
                  })}
              </div>
            );
          } else {
            return (
              <ListItem button key={i.key} onClick={i.handleClick} selected={i.selected}>
                <ListItemIcon>
                  <MaterialIcon icon={i.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={i.text}
                  primaryTypographyProps={{ fontWeight: i.selected ? 'bold' : '400' }}
                  secondary={i.subtitle}
                />
                {i.avatar}
              </ListItem>
            );
          }
        });
      }
    } else {
      return null;
    }
  }
};

export default LayerMenuActions;
