import { axios, geoApiGouv } from '../../../lib/axios';
import unique from '../../../utils/unique';
import { natureProduitCode } from '../enums';

const getCommuneInfosFromXY = async ([x, y]) => {
  const [data] = await geoApiGouv.get(`/communes?lon=${x}&lat=${y}&fields=code,nom`);
  return data;
};

const getCampagnes = async (kelcamp) => {
  const { divers } = await axios.get('/divers/campagne/' + kelcamp);
  return divers.sort((a, b) => {
    return b.campsur4 - a.campsur4; // order items by campsur4
  });
};

const getParcellesFromAPI = async ({ extent, params }) => {
  let queryParams = '';

  if (params) {
    queryParams = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');
  }

  let searchURL = '/parcelles-api/search';

  if (extent) {
    searchURL += '?bbox=' + extent.join(',');
  }

  if (queryParams) {
    if (extent) {
      searchURL += '&';
    } else {
      searchURL += '?';
    }
    searchURL += queryParams;
  }

  let endpoints = [searchURL];

  if (params.parmculture && params.parmculture === natureProduitCode.couvertMellifere) {
    if (extent) {
      endpoints = [
        `/parcelles-api/couvert-mellifere?bbox=${extent.join(',')}`,
        `/parcelles-api/search?bbox=${extent.join(',')}&pairedonly=true`,
      ];
    } else {
      endpoints = [`/parcelles-api/couvert-mellifere?${queryParams}`];
    }
  }

  if (params.parmculture && params.parmculture === natureProduitCode.vinasse) {
    if (extent) {
      endpoints = [`/parcelles-api/vinasse/search?bbox=${extent.join(',')}`];
    } else {
      endpoints = [`/parcelles-api/vinasse/search?${queryParams}`];
    }
  }

  const results = await Promise.all(endpoints.map((endpoint) => axios.get(endpoint)));

  let features = [];

  results.forEach((result) => {
    features.push(...result.features);
  });

  return {
    type: 'FeatureCollection',
    features,
  };
};

const getParcellesFromSIA = async ({ commune, planteur }) => {
  const { divers } = await axios.get(
    `/divers/parcelle/parcellaire?commune=${commune || ''}&planteur=${planteur || ''}`
  );
  return divers.dsappareill.sort((a, b) => {
    return b.cpcu - a.cpcu; // order items by cpcu
  });
};

const getCulture = async (params) => {
  const { divers } = await axios.get('/divers/culturepac', {
    params,
    paramsSerializer: function paramsSerializer(params) {
      return Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },
  });
  return divers?.dsculpac;
};

const getAllCultures = async () => {
  const tasks = [];
  // skip culture 'T'
  const strippedKeys = Object.keys(natureProduitCode).filter(
    (k) => natureProduitCode[k] !== natureProduitCode.planteur
  );

  strippedKeys.map((k) => {
    tasks.push(() => getCulture({ natpro: natureProduitCode[k] }));
  });

  const arrayOfPromises = tasks.map((task) => task());
  const cultures = await Promise.all(arrayOfPromises).then((response) => {
    return [].concat.apply([], response); // flatten array of arrays
  });

  return cultures;
};

const getCommunes = async ({ text }) => {
  const { divers } = await axios.get(`/divers/commune?choix=1&nom=${text || ''}`);
  return divers;
};

const getEtaArrachage = async () => {
  const { divers } = await axios.get(`/divers/eta`, {
    params: {
      typeent: 'A',
    },
  });
  return divers?.dsentreprise;
};

const getGroupeArrachage = async () => {
  const { divers } = await axios.get(`/divers/groupearrachage`);
  return divers?.dsgrarr;
};

const getSecteur = async ({ text }) => {
  const { divers } = await axios.get(`/divers/secteurgeo?chaine=${text.toUpperCase() || ''}`);
  return divers?.dssecteur;
};

const getUsines = async () => {
  const { divers } = await axios.get('/divers/usine/cu');
  return divers;
};

const getTechList = async () => {
  const { divers } = await axios.get('/divers/technicien/recherche?carto=O&windows=O');
  return divers;
};

const getTechType = async () => {
  const { divers } = await axios.get('/divers/technicien/types');
  return divers;
};

const getPlanteurByNom = async ({ text }) => {
  const { divers } = await axios.get(`/divers/planteur/recherche?nom=${text}`);
  return divers;
};

const getPlanteurByCode = async ({ text }) => {
  const { divers } = await axios.get(`/divers/planteur/recherche?planteur=${text}`);
  const distinctCpcu = unique(divers, 'cpcu');
  return distinctCpcu;
};

const newParcelleCouvertMellifereToAPI = async (parcelle) => {
  const response = await axios.post('/parcelles-api/couvert-mellifere', parcelle);
  return response;
};

const updateParcelleCouvertMellifereToAPI = async (parcelle) => {
  const response = await axios.put('/parcelles-api/couvert-mellifere', parcelle);
  return response;
};

const deleteParcelleCouvertMellifereToAPI = async (gid) => {
  const response = await axios.delete(`/parcelles-api/couvert-mellifere/${gid}`);
  return response;
};

const sendParcelleToSIA = async (parcelle) => {
  const { divers } = await axios.post('/divers/parcelle/parcellaire', parcelle);
  return divers;
};

const updateParcelleToAPI = async (parcelle) => {
  const response = await axios.put('/parcelles-api/parcelles', parcelle);
  return response;
};

const createParcelleToAPI = async (parcelle) => {
  const response = await axios.post('/parcelles-api/parcelles', parcelle);
  return response;
};

const deleteParcelleToAPI = async (tablename, id_parcel) => {
  const response = await axios.delete(
    `/parcelles-api/parcelles?tablename=${tablename}&id_parcel=${id_parcel}`
  );
  return response;
};

export {
  getEtaArrachage,
  getGroupeArrachage,
  getCulture,
  getAllCultures,
  getCommuneInfosFromXY,
  getParcellesFromAPI,
  getParcellesFromSIA,
  getCampagnes,
  getSecteur,
  getUsines,
  getCommunes,
  getTechList,
  getTechType,
  getPlanteurByNom,
  getPlanteurByCode,
  sendParcelleToSIA,
  updateParcelleToAPI,
  createParcelleToAPI,
  newParcelleCouvertMellifereToAPI,
  deleteParcelleCouvertMellifereToAPI,
  updateParcelleCouvertMellifereToAPI,
  deleteParcelleToAPI,
};
